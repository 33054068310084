/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

.required-asterisk {
  color: red;
  margin-left: 3px;
}

.ph-item {
  border: none;
  padding: 15px 5px;
}

.autowidth {
  width: auto !important;
}

.channelSelector .mat-button-toggle-label-content {
  line-height: 36px !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 0.75em 0.75em !important;
}

.mat-drawer-inner-container {
  padding-right: 15px !important;
  overflow-x: hidden !important;
}

.mat-drawer-inner-container .mat-form-field-wrapper {
  padding-bottom: 0px !important;
}

.mat-drawer-inner-container .mat-form-field-underline {
  bottom: 0px !important;
}


.mat-toolbar.mat-primary.top-navbar {
  background-color: black;
  /* height: 7vh; */
}

/* .mat-toolbar-row,
.mat-toolbar-single-row {
  height: 8vh;
} */

.mat-button-toggle-group {
  flex-wrap: wrap;
}

.planning-view-sidenav .mat-button-toggle-button {
  /* min-width: 70px; */
  font-size: 0.85em;
}

.planning-view-sidenav button {
  letter-spacing: 0 !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.planning-view-sidenav .mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle {
  border: none;
}

.planning-view-sidenav .mat-button-toggle-group-appearance-standard .mat-button-toggle .mat-button-toggle {
  border: 1px solid grey;
}


.container-fluid {
  padding: 0px;
}

#selection-page-main {
  overflow: hidden;
  overflow-y: auto;
}

.selection-page-panel:nth-child(2) {
  z-index: 1;
}

.selection-container {
  background-color: white;
  box-shadow: 11px 10px 40px -7px rgba(0, 0, 0, 0.65);
  -webkit-box-shadow: 11px 10px 40px -7px rgba(0, 0, 0, 0.65);
  -moz-box-shadow: 11px 10px 40px -7px rgba(0, 0, 0, 0.65);
}


body {
  overflow: hidden;
  height: 100vh;
}

.fullheightrow {
  height: 100%;
  --bs-gutter-x: 0px;
}

.selection-page-panel {
  /* background-color: gold; */
  background-image: url("./assets/images/bg_pattern.png");
}

.mat-drawer-content {
  overflow: hidden !important;
}

.seasonTitle {
  text-align: center !important;
  font-weight: bold !important;
  font-size: 1.3em !important;
}

#totalSeasonTable .seasonTitle {
  background-color: #cdedf3 !important;
}

#previousSeasonTable .seasonTitle {
  background-color: #d4cdf3 !important;
}

#actualSeasonTable .seasonTitle {
  background-color: #f3cdce !important;
}

#comingSeasonTable .seasonTitle {
  background-color: #f0f3cd !important;
}

#futureSeasonTable .seasonTitle {
  background-color: #d0f3cd !important;
}


/* .mat-toolbar {
  height: 8vh;
} */

.planning-view-tree-node {
  padding: 3px !important;
}

.mat-tree-node {
  line-height: 25px;
  min-height: 0px;
}

.planning-view-tree {
  min-height: 12vw !important;
  overflow: auto !important;
  height: 100% !important;
}

.forecastrow {
  background-color: #d5f1d4 !important;
}

.seasonTable {
  font-size: 0.65em;

  table-layout: fixed;
  width: 100%
}

.seasonTableRow:nth-child(odd) .seasonTableRowCell,
.seasonTableRow:nth-child(odd) .seasonTableRowCell4Merge {
  background-color: #e9ecef
}

.seasonTableRowCell,
.seasonTableRowCell4Merge,
.seasonTableRowCell12Merge,
.seasonTableRowCellSeasonMerge {
  text-align: center;
  border: 0.5px solid #b7cbdf;
  vertical-align: top;
  overflow: hidden;
  outline-width: 0;
  background-color: #f9f9f9;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.seasonTableRowHeader {
  border: 1px solid #ccc;
  white-space: nowrap;
  background-color: #e9e9e9;
  text-align: center;
  /* width:1%; */
  width: 100px;
}

.seasonTableRowForecastPeriod input {
  border: none;
  width: 100%;
}

.seasonTableRowWeeks .seasonTableRowCell {
  background-color: #ffe8d1;
}

.seasonTableRowQuarters .seasonTableRowCell12Merge {
  background-color: #e4f3fc;
}

.seasonTableRowPeriods .seasonTableRowCell4Merge {
  background-color: #f2f2f2;
}

.seasonTableRowCell {
  width: 1%;
  background-color: #f9f9f9;
}

.seasonTableRowCellSeasonMerge {
  font-size: 1.7em;
  font-weight: bold;
  background-color: #c4e1fb;
}

.seasonTableRowCellEnabled {
  background-color: #d5f1d4 !important;
  text-align: center;
  border: 0.5px solid #b7cbdf;
  /* vertical-align: top; */
  overflow: hidden;
  outline-width: 0;
}

.seasonTableRowCellDisabled {
  /* background-color: #6a6a6a !important;
    color: white; */
}

.seasonTableInputRow input,
.seasonTableInputRow input:focus-visible {
  border: none;
  outline: none;
  text-align: center;
  width: 100%;
}

.seasonTableRowForecastPeriod input,
.seasonTableRowForecastPeriod input:focus-visible {
  border: none;
  outline: none;
  text-align: center;
}

.seasonTableIntermediateData .seasonTableRowCell {
  background-color: #bae7c0 !important;
}

.seasonTableRawData .seasonTableRowCell {
  background-color: #9bdb86 !important;
}

.currentWeek {
  color: red;
  font-weight: bold;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 35px;
}

.mat-tab-body {
  padding: 20px !important;
}

.error-snackbar {
  background-color: #ff3030c9;
  color: white;
  text-align: center;
}

.general-snackbar {
  background-color: #a0f7ba;
  color: black;
  text-align: center;
}

.copy-controls-button {
  margin: 5px 0px !important;
  padding: 0px !important;
  min-width: auto !important;
  width: 40px;
}

.dialogboxname {
  font-weight: bold;
}

.mat-tooltip {
  margin: 2px !important;
}

.nav-version {
  font-size: 0.6em;
  line-height: 1.2em;
}

/* tooltip delay */
.tooltip>td {
  animation-name: delayedFadeIn;
  animation-duration: 2s;
}

@keyframes delayedFadeIn {
  0% {
    opacity: 0;
  }

  99% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.season-table-header-input {
  text-align: center;
  border: 0.3px solid;
  width: 4em;
}

.season-table-header-label {
  margin-right: 6px;
}

.season-table-header-td {
  float: right;
  width: 9.5em;
}

.planning-view-status-bar {
  background-color: #e3e3e3;
  text-align: center;
}

.planning-view-main-pane {
  padding-top: 0px;
  padding-left: 15px;
  padding-right: 15px;
  height: 84vh;
  overflow-y: auto;
}

/* Simulate section */
.table-container {
  position: relative;
}

.simulateTable {
  font-size: 0.65em;
  width: 85%;
  margin: 0 auto;
}

.simulateTableHeaderRow {
  border: 1px solid #ccc;
  background-color: #c4e1fb;
  text-align: center;
  font-weight: bold;
  font-size: 1.3em;
}

.simulateTableHeaderCell {
  border: 0.5px solid black;
  padding: 5px;
}

.simulateTableSubHeaderRow {
  border: 1px solid #ccc;
  background-color: #b8daed;
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
}

.simulateTableSubHeaderCell {
  border: 0.5px solid black;
  padding: 5px;
}

.simulateTableRowCell {
  text-align: center;
  border: 0.5px solid black;
  font-size: 1.2em;
  padding: 5px;
}

.simulateTableRow:nth-child(odd) .simulateTableRowCell {
  background-color: #e9ecef
}

.simulateTableTotalRow {
  background-color: #d5d5d5 !important;
  font-weight: bold;
}

.organizationSummaryTable {
  font-size: 0.65em;
  width: 85%;
  margin: 0 auto;
}

.organizationSummaryTableHeaderRow {
  border: 1px solid #ccc;
  background-color: #c4e1fb;
  text-align: center;
  font-weight: bold;
  font-size: 1.3em;
}

.organizationSummaryTableHeaderCell {
  border: 0.5px solid black;
  padding: 5px;
}

.organizationSummaryTableSubHeaderRow {
  border: 1px solid #ccc;
  background-color: #b8daed;
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
}

.organizationSummaryTableSubHeaderCell {
  border: 0.5px solid black;
  padding: 5px;
}

.organizationSummaryTableRowCell {
  text-align: center;
  border: 0.5px solid black;
  font-size: 1.2em;
  padding: 5px;
}

.organizationSummaryTableRow:nth-child(odd) .organizationSummaryTableRowCell {
  background-color: #e9ecef
}

.organizationSummaryTableTotalRow {
  background-color: #d5d5d5 !important;
  font-weight: bold;
}

.simulateDateSelector {
  width: 16vh;
  margin-top: 15px;
  margin-right: 15px;
}

/* Planning view chart  start*/
.planning-view-chart {
  padding-bottom: 10px;
}

.pin-planning-view-chart {
  position: sticky;
  top: 0px;
  z-index: 9999;
  background: #fafafa;
}

.unpin-planning-view-chart {
  position: relative;
  top: 0px;
  z-index: 9999;
  background: #fafafa;
}

/* Planning view chart  end*/
.quarterBorder {
  border-right: 0.5px solid rgb(112, 128, 144);
}


.control-title {

  font-size: 1em;
  display: block;
  margin-bottom: 10px;

}

.control-sub-title {

  font-size: 0.8em;

}

.mat-form-field-appearance-fill .mat-form-field-flex,
.mat-form-field-appearance-fill .mat-mdc-select-min-line,
.mat-form-field-appearance-fill .mat-mdc-form-field-infix {
  padding: 0.5em 0.5em 0.2rem !important;
  font-size: 0.75em;
}

.mdc-list-item--with-one-line{
  height: 40px !important;
}

.mdc-list-item--selected{
  background-color: rgba(0,0,0,.12) !important;
  color: rgba(0,0,0,.87) !important;;
}


.mat-mdc-text-field-wrapper {
  padding: 0 !important;
}

mat-option {
  .mdc-list-item__primary-text {
    font-size: 11px !important;
  }
}

mat-list-option {
  .mdc-list-item__primary-text {
    color: rgba(0,0,0,.87) !important;
  }
}
#createCopy {
  mat-icon {
    margin: 5px 0 0 !important;
    width: 32px !important;
    height: 32px !important;
  }
}

.mat-icon {
  font-size: 24px !important;
  margin: 0 !important;
}

mat-selection-list.mdc-list {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

body.mat-typography {
  letter-spacing: 0 !important;
}

.settings-username {
  letter-spacing: 0 !important;
  padding-left: 10px !important;
}

.mat-mdc-raised-button>.mat-icon {
  height: 24px !important;
  width: 24px !important;
}

mat-option {
  font-size: 14px !important;
  min-height: 33px !important;
}
.mat-mdc-form-field-infix {
  min-width: 205px !important;
}

.mat-form-field-appearance-fill .mdc-text-field--filled {
  height: 40px;
}

.mat-list-base .mat-list-item,
.mdc-list-item__content .mdc-list-item__primary-text,
.mat-list-base .mat-list-option {
  height: 3.4em !important;
  font-size: 0.85em;
  letter-spacing: 0!important;
}

mat-card-content {
  padding: 0!important;
}
.mat-mdc-menu-item .mat-icon-no-color, .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, .54) !important;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px !important;
  height: 40px !important;
}
.mat-mdc-dialog-container .mdc-dialog__content {
  color: black !important;
}

.no-top-margin {
  margin-top: 0px
}

.planning-view-toolbar .mat-mdc-form-field-infix{
  
  width: 130px !important;
  min-width:  90px !important;
  min-height: 24px;
  height: 24px;
  position: relative;
  top: 10px;
  font-size: 11px;
}

.planning-view-toolbar {
  button {
    padding: 8px !important;
  }
}

#datePicker .mat-mdc-form-field-infix{
  width: 90px !important;
}

#kpi .mat-mdc-form-field-infix{
  width: 150px !important;
}

#graphCheckbox label, #rawCheckbox label {
  font-size: 12.6px;
  font-weight: 500;
  margin: 0px 5px;
}

#graphCheckbox,  #rawCheckbox{
  position: relative;
  bottom: 2px;
}

.planning-view-toolbar .mat-mdc-form-field-subscript-wrapper {
  height: 0!important;
}

.simulation-view .mat-form-field,
.organization-summary-view .mat-form-field {
  margin: 10px 10px 0px 10px;
}

.mdc-checkbox__background {
  width: 16px !important;
  height: 16px !important;
  top: 50% !important; /* Added */
  left: 50% !important; /* Added */
  transform: translate(-50%, -50%) !important; /* Added */
}

 .mat-mdc-checkbox-touch-target {
  width: 20px !important;
  height: 20px !important;
}

.mdc-checkbox {
  width: 20px !important;
  height: 20px !important;
  padding: 0 !important;
}

.InputFieldsLocked {
  background-color: #d8dfdc !important;
}

.InputFieldsUnLocked {
  background-color: #d5f1d4 !important;
}


/* media queries for selection page */
/* normal laptop screen */
@media only screen and (min-width: 1900px) and (max-width: 1920px) {
  .selection-page-sub-container {
    flex: 0 0 auto;
    width: 33.3333%;
  }

  .selection-page-main-container {
    flex: 0 0 auto;
    width: 33.3333%;
  }
}

/* normal screen with 80% and below zoom */
@media only screen and (min-width: 2135px) {
  .selection-page-sub-container {
    flex: 0 0 auto;
    width: 37%;
  }

  .selection-page-main-container {
    flex: 0 0 auto;
    width: 26%;
  }
}

/* normal screen with 90% zoom */
@media only screen and (min-width: 1921px) and (max-width: 2134px) {
  .selection-page-sub-container {
    flex: 0 0 auto;
    width: 35%;
  }

  .selection-page-main-container {
    flex: 0 0 auto;
    width: 30%;
  }
}

/* normal screen with 110% zoom */
@media only screen and (min-width: 1745px) and (max-width: 1899px) {
  .selection-page-sub-container {
    flex: 0 0 auto;
    width: 31%;
  }

  .selection-page-main-container {
    flex: 0 0 auto;
    width: 38%;
  }
}

/* normal screen with 125% zoom */
@media only screen and (min-width: 1536px) and (max-width: 1744px) {
  .selection-page-sub-container {
    flex: 0 0 auto;
    width: 31%;
  }

  .selection-page-main-container {
    flex: 0 0 auto;
    width: 38%;
  }
}

/* normal screen with 150% zoom */
@media only screen and (min-width: 1280px) and (max-width: 1535px) {
  .selection-page-sub-container {
    flex: 0 0 auto;
    width: 27.5%;
  }

  .selection-page-main-container {
    flex: 0 0 auto;
    width: 45%;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .selection-page-sub-container {
    flex: 0 0 auto;
    width: 26%;
  }

  .selection-page-main-container {
    flex: 0 0 auto;
    width: 48%;
  }
}

html {
  overflow: hidden !important;
}

.mat-slide-toggle-sum-sim {
  vertical-align: top;
  padding: 8px 5px 0px 5px;
}

.mat-slide-toggle-sum-sim .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, .38) !important;
}

.mat-slide-toggle-sum-sim .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  background-color: #673ab7;
}

.aggregated-pm-span {
  font-weight: bold;
  color: #612ebb;
}


.totalplanviewtable .totalplanviewtablerow:nth-child(2) td {
  font-weight: bold;
}

.totalviewrequesttable {
  width: 50% !important;
  margin: 0 auto
}

.totalviewrequesttable-heading {
  font-weight: bold;
}

.btntotalviewrequestgenerateview {
  margin: 7px !important;
}


.tree-node-icon {
  padding: 0px 5px;
  margin: 0px 5px 0px 0px;
  font-size: 11px;
}

.tree-node-icon-Department {
  color: #4790e5;
}

.tree-node-icon-Section {
  color: #9dad00;
}

.tree-node-icon-Division {
  color: #a91c00;
  ;
}

.tree-node-icon-CustomerGroup {
  color: #00972b;
  ;
}

.tree-node-icon-GarmentGroup {
  color: #0095a9;
}

.tree-node-icon-Total {
  color: #9d9d9d;
}

.tree-node-icon-Brand {
  color: #e9a700;
}

.custom-select-panel {
  overflow: hidden !important;
}

.custom-week-selection {
  outline: none;
  border: none;
  padding: 10px;
  width: 100%;
  background-color: #114270;
  color: white;
}

.custom-week-section {
  overflow: auto;
  max-width: 280px;
  padding-top: 0;
  padding-bottom: 0;
  max-height: 210px;
  border-radius: 4px;
  outline: 0;
}

.copy-lock-message {
  position: sticky;
  color: red;
  text-align: center;
  padding: 5px;
}

.selected-retrieval-mode-text {
  position: sticky;
  color: black;
  text-align: center;
  padding: 5px;
}

caption {
  display: none;
}

.table-gap {
  width: 20px;
  padding: 30px !important;
}
